import React, { useState, useEffect, useRef } from "react";
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import html2canvas from "html2canvas";
import logo from "./yatayati.png";
import axios from "axios";
import { toast } from "react-toastify";

const Estimation = () => {
  const invoiceRef = useRef();
  const today = new Date();
  const formattedDate = today.toISOString().split("T")[0];

  const [formData, setFormData] = useState({
    est_no: "",
    company_name: "",
    contact_number: "",
    date: formattedDate,
    rows: [],
    sub_total: "",
    discount_type: "percentage",
    discount: "",
    afterdiscount: "",
    taxpercentage: "",
    taxamount: "",
    total: "",
  });

  const fetchNextEstimation = async () => {
    try {
      const response = await axios.get(
        "http://localhost:5000/get/nextestimation-no"
      );
      const nextEstimationValue = response.data.nextEstimationValue;
      setFormData((prev) => ({
        ...prev,
        est_no: nextEstimationValue,
      }));
    } catch (error) {
      console.error("Error fetching next estimation value", error);
    }
  };

  useEffect(() => {
    fetchNextEstimation();
  }, []);

  const handleInputChange = (e) => {
    setFormData((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };

  const handleExtraDiscount = (type) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      discount_type: type,
    }));
  };

  const [rows, setRows] = useState([
    {
      id: 1,
      item: "",
      quantity: "",
      rate: "",
      amount: "",
    },
  ]);

  const handleAddRow = () => {
    const newItemId = rows.length > 0 ? rows[rows.length - 1].id + 1 : 1;
    const newRow = {
      id: newItemId,
      item: "",
      quantity: "",
      rate: "",
      amount: "",
    };
    setRows((prevRows) => [...prevRows, newRow]);
  };

  const handleFielddata = (index) => (event) => {
    const { name, value } = event.target;
    const newRows = [...rows];
    newRows[index][name] = value;

    let amount = 0;
    if (name === "quantity" || name === "rate") {
      const quantity = parseFloat(newRows[index].quantity) || 0;
      const rate = parseFloat(newRows[index].rate) || 0;
      amount = quantity * rate;
      newRows[index].amount = amount;
    }

    const subTotal = newRows.reduce(
      (total, row) => total + parseFloat(row.amount || 0),
      0
    );
    const total = parseFloat(subTotal) + parseFloat(formData.taxamount || 0);

    setRows(newRows);
    setFormData((prevFormData) => ({
      ...prevFormData,
      rows: newRows,
      sub_total: subTotal,
      total: total,
    }));
  };

  useEffect(() => {
    let finalAmount = formData.total;
    const extraDiscount = parseFloat(formData.discount) || 0;

    if (formData.discount_type === "percentage") {
      finalAmount =
        formData.sub_total - (extraDiscount / 100) * formData.sub_total;
    } else {
      finalAmount = formData.sub_total - extraDiscount;
    }

    const gstAmount =
      (parseFloat(finalAmount || 0) * parseFloat(formData.taxpercentage || 0)) /
      100;

    const total = parseFloat(finalAmount || 0) + parseFloat(gstAmount || 0);

    setFormData((prev) => ({
      ...prev,
      afterdiscount: finalAmount,
      taxamount: gstAmount,
      total: total,
    }));
  }, [
    formData.sub_total,
    formData.discount,
    formData.discount_type,
    formData.taxpercentage,
    formData.taxamount,
  ]);

  const deleteRow = (id, index) => {
    if (index === 0) {
      return;
    }

    const updatedRows = rows.filter((_, i) => i !== index);

    const reIndexedRows = updatedRows.map((row, i) => ({
      ...row,
      id: i + 1,
    }));

    const subTotal = reIndexedRows.reduce(
      (total, row) => total + parseFloat(row.amount || 0),
      0
    );

    let finalAmount = subTotal;
    const extraDiscount = parseFloat(formData.discount) || 0;

    if (formData.discount_type === "percentage") {
      finalAmount = finalAmount - (extraDiscount / 100) * subTotal;
    } else {
      finalAmount = finalAmount - extraDiscount;
    }

    const gstAmount =
      (parseFloat(finalAmount || 0) * parseFloat(formData.taxpercentage || 0)) /
      100;

    const total = parseFloat(finalAmount || 0) + parseFloat(gstAmount || 0);

    setRows(reIndexedRows);
    setFormData((prevFormData) => ({
      ...prevFormData,
      sub_total: subTotal,
      afterdiscount: finalAmount,
      taxamount: gstAmount,
      total: total,
    }));
  };

  const handleGeneratePDF = () => {
    const input = invoiceRef.current;
    html2canvas(input).then((canvas) => {
      const pdf = new jsPDF("p", "mm", "a4");
      const margin = 5;
      const pageWidth = pdf.internal.pageSize.getWidth();
      pdf.setFontSize(20);
      pdf.setFont("bold");
      pdf.text("I N V O I C E", 14, 22);
      pdf.setFontSize(14);
      pdf.setFont("normal");
      pdf.text("YATA YATI INFO SOLUTIONS", 14, 30);
      pdf.addImage(logo, "PNG", pageWidth - 60, 10, 40, 30);

      pdf.text("Name of Company:", 14, 60);
      pdf.setTextColor(108, 117, 125);
      pdf.text(
        `${formData.company_name}`,
        pdf.getTextWidth("Name of Company: ") + 14,
        60
      );

      pdf.setTextColor("black");
      pdf.text("Contact No: ", 14, 70);
      pdf.setTextColor(108, 117, 125);
      pdf.text(
        `${formData.contact_number}`,
        pdf.getTextWidth("Contact No: ") + 14,
        70
      );

      pdf.text(`${formData.date}`, pageWidth - 60, 70);

      let finalY;
      // Adding the table
      pdf.autoTable({
        startY: 80,
        headStyles: {
          fillColor: [255, 255, 255],
          textColor: [0, 0, 0],
          lineWidth: { top: 0.5, right: 0, bottom: 0.5, left: 0 },
          lineColor: [13, 110, 253],
        },
        alternateRowStyles: {
          fillColor: [255, 255, 255],
        },

        head: [["#", "Item", "Quantity", "Rate", "Amount"]],
        body: formData.rows.map((row, index) => [
          index + 1,
          row.item,
          row.quantity,
          row.rate,
          row.amount,
        ]),
        didDrawPage: (data) => {
          finalY = data.cursor.y;
        },
        width: pdf.internal.pageSize.getWidth(),
      });

      pdf.setTextColor("black");
      pdf.text("SUB TOTAL", 14, finalY + 30);
      pdf.setTextColor(108, 117, 125);
      pdf.text(`${formData.sub_total}`, pageWidth - 40, finalY + 30);

      pdf.setDrawColor(13, 110, 253);
      pdf.line(14, finalY + 35, pageWidth - 14, finalY + 35);

      pdf.setTextColor("black");
      pdf.text(`DISCOUNT (${formData.discount_type})`, 14, finalY + 42);
      pdf.setTextColor(108, 117, 125);
      pdf.text(`${formData.discount}`, pageWidth - 40, finalY + 42);

      pdf.setDrawColor(13, 110, 253);
      pdf.line(14, finalY + 47, pageWidth - 14, finalY + 47);

      pdf.setTextColor("black");
      pdf.text("TAX(Percentage)", 14, finalY + 54);
      pdf.setTextColor(108, 117, 125);
      pdf.text(`${formData.taxpercentage}`, pageWidth - 40, finalY + 54);

      pdf.line(14, finalY + 59, pageWidth - 14, finalY + 59);

      pdf.setTextColor("black");
      pdf.text("TAX(In Rupees)", 14, finalY + 66);
      pdf.setTextColor(108, 117, 125);
      pdf.text(`${formData.taxamount}`, pageWidth - 40, finalY + 66);

      pdf.line(14, finalY + 71, pageWidth - 14, finalY + 71);

      pdf.setTextColor("black");
      pdf.text("TOTAL", 14, finalY + 78);
      pdf.setTextColor(108, 117, 125);
      pdf.text(`${formData.total}`, pageWidth - 40, finalY + 78);

      pdf.line(14, finalY + 83, pageWidth - 14, finalY + 83);

      const pageHeight = pdf.internal.pageSize.getHeight();
      pdf.setFontSize(15);
      pdf.text(
        "Info@yatayati.com | 4th floor, Alwal, Hyderabad-500010 | +918886802039",
        20,
        pageHeight - 20
      );

      // Open the PDF in a new tab
      const pdfBlob = pdf.output("blob");
      const url = URL.createObjectURL(pdfBlob);
      window.open(url, "_blank");
    });
  };

  const handleEstimationSubmit = (e) => {
    e.preventDefault();

    console.log(formData);
    if (editest_no) {
      axios
        .put(`http://localhost:5000/edit/estimation/${editest_no}`, formData)
        .then((response) => {
          toast.success("Estimation edited successfully");
          setEditest_no("");
        })
        .catch((error) => {
          console.log(error);
        });

      handleGeneratePDF();
      setFormData({
        company_name: "",
        contact_number: "",
        date: formattedDate,
        rows: [],
        sub_total: "",
        discount: "",
        discount_type: "",
        taxpercentage: "",
        taxamount: "",
        total: "",
      });
      setRows([
        {
          id: 1,
          item: "",
          quantity: "",
          rate: "",
          amount: "",
        },
      ]);
      fetchNextEstimation();
    } else {
      axios
        .post("http://localhost:5000/post/estimation", formData)
        .then((response) => {
          toast.success("estimation added successfully");
          const newEstimation = { ...formData, id: response.data.id };
          setEstimationData((prev) => [...prev, newEstimation]);
        })
        .catch((error) => {
          console.log(error);
        });

      handleGeneratePDF();
      setFormData({
        company_name: "",
        contact_number: "",
        date: formattedDate,
        rows: [],
        sub_total: "",
        discount: "",
        discount_type: "",
        taxpercentage: "",
        taxamount: "",
        total: "",
      });
      setRows([
        {
          id: 1,
          item: "",
          quantity: "",
          rate: "",
          amount: "",
        },
      ]);
      fetchNextEstimation();
    }
  };

  const [estimationData, setEstimationData] = useState([]);
  useEffect(() => {
    axios
      .get("http://localhost:5000/get/estimation")
      .then((response) => {
        setEstimationData(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const [estViewData, setEstViewData] = useState([]);
  const handleView = async (est_no) => {
    try {
      const response = await axios.get(
        `http://localhost:5000/get/viewestimation/${est_no}`
      );
      if (response.data.success) {
        setEstViewData(response.data.result);
        console.log(response.data.result);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleDelete = async (est_no) => {
    try {
      if (window.confirm("Are you sure you want to delete?")) {
        const response = await axios.delete(
          `http://localhost:5000/delete/estimation/${est_no}`
        );
        if (response.data.success) {
          toast.success("Estimation deleted successfully!");
          setEstimationData(
            estimationData.filter((each) => each.est_no !== est_no)
          );
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const [editest_no, setEditest_no] = useState("");
  const handleEdit = async (est_no) => {
    try {
      const response = await axios.get(
        `http://localhost:5000/get/editestimate/${est_no}`
      );
      if (response.data.success) {
        setEditest_no(est_no);
        const { responseData } = response.data;
        const date = new Date(responseData.date);
        const formattedDate = `${date.getFullYear()}-${String(
          date.getMonth() + 1
        ).padStart(2, "0")}-${String(date.getDate()).padStart(2, "0")}`;
        setFormData({
          ...responseData,
          date: formattedDate,
        });
        setRows(responseData.rows);
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <React.Fragment>
      <form
        className="container mt-3 text-center"
        onSubmit={handleEstimationSubmit}
      >
        <div className="d-flex">
          <label
            htmlFor="est_no"
            className="text-secondary fw-bold text-start col-lg-2"
          >
            Estimation No
          </label>
          <div className="col-lg-5">
            <input
              className="form-control"
              type="text"
              name="est_no"
              value={formData.est_no}
              readOnly
            />
          </div>
        </div>

        <div className="d-flex mt-3">
          <label
            htmlFor="company_name"
            className="text-secondary fw-bold text-start col-lg-2"
          >
            Company Name
          </label>
          <div className="col-lg-5">
            <input
              className="form-control"
              type="text"
              name="company_name"
              value={formData.company_name}
              onChange={handleInputChange}
            />
          </div>
        </div>

        <div className="d-flex mt-3">
          <label
            htmlFor="contact_no"
            className="text-secondary fw-bold text-start col-lg-2"
          >
            Contact Number
          </label>
          <div className="col-lg-5">
            <input
              className="form-control"
              type="text"
              name="contact_number"
              value={formData.contact_number}
              onChange={handleInputChange}
            />
          </div>
        </div>

        <div className="d-flex mt-3">
          <label
            htmlFor="date"
            className="text-secondary fw-bold text-start col-lg-2"
          >
            Date
          </label>
          <div className="col-lg-5">
            <input
              className="form-control"
              type="date"
              name="date"
              value={formData.date}
              onChange={handleInputChange}
            />
          </div>
        </div>

        <table className=" mt-3 table hovered">
          <thead className="table-secondary">
            <tr>
              <th>Item_ID</th>
              <th>Item</th>
              <th>Qty/hrs</th>
              <th>Rate</th>
              <th>Amount</th>
              <th>Action</th>
            </tr>
          </thead>

          <tbody>
            {rows.map((row, index) => (
              <tr key={index}>
                <td>{row.id}</td>
                <td>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Item name"
                    value={row.item}
                    name="item"
                    onChange={handleFielddata(index)}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Quantity/Hours"
                    value={row.quantity}
                    name="quantity"
                    onChange={handleFielddata(index)}
                  />
                </td>
                <td>
                  <input
                    type="number"
                    placeholder="Rate"
                    className="form-control"
                    value={row.rate}
                    name="rate"
                    onChange={handleFielddata(index)}
                  />{" "}
                </td>

                <td>
                  {" "}
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Amount"
                    value={row.amount}
                    name="amount"
                    readOnly
                  />
                </td>
                <td>
                  <button
                    type="button"
                    className="btn btn-sm btn-danger"
                    onClick={() => deleteRow(row.id, index)}
                  >
                    Delete
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <div>
          <button
            className="btn btn-primary btn-sm fw-bold float-start "
            type="button"
            onClick={handleAddRow}
          >
            Add Row
          </button>
        </div>

        <div className="d-flex mt-5 pt-3">
          <label
            htmlFor="sub_total"
            className="text-secondary fw-bold text-start col-lg-2"
          >
            Sub_Total
          </label>
          <div className="col-lg-5">
            <input
              className="form-control"
              type="text"
              name="sub_total"
              value={formData.sub_total}
              readOnly
            />
          </div>
        </div>

        <div className="d-flex mt-3">
          <label
            htmlFor="discount"
            className="text-secondary fw-bold text-start col-lg-2"
          >
            Discount
          </label>
          <div className="col-lg-5 d-flex">
            <input
              type="number"
              name="discount"
              value={formData.discount}
              min="0"
              className=" form-control "
              onChange={handleInputChange}
            />
            <select
              className="form-select"
              value={formData.discount_type}
              onChange={(event) => handleExtraDiscount(event.target.value)}
            >
              <option value="percentage">%</option>
              <option value="Rs">Rs</option>
            </select>
          </div>
        </div>

        <div className="d-flex mt-3">
          <label
            htmlFor="taxpercentage"
            className="text-secondary fw-bold text-start col-lg-2"
          >
            Tax
          </label>
          <div className="col-lg-5">
            <input
              type="text"
              className="form-control"
              name="taxpercentage"
              value={formData.taxpercentage}
              onChange={handleInputChange}
            />
          </div>
        </div>

        <div className="d-flex mt-3">
          <label
            htmlFor="taxamount"
            className="text-secondary fw-bold text-start col-lg-2"
          >
            Tax(in Rupees)
          </label>
          <div className="col-lg-5">
            <input
              className="form-control"
              type="text"
              name="taxamount"
              value={formData.taxamount}
              readOnly
            />
          </div>
        </div>

        <div className="d-flex mt-3">
          <label
            htmlFor="total"
            className="text-secondary fw-bold text-start col-lg-2"
          >
            Total
          </label>
          <div className="col-lg-5">
            <input
              className="form-control"
              type="text"
              name="total"
              value={formData.total}
              onChange={handleInputChange}
            />
          </div>
        </div>

        <button
          type="submit"
          className="btn btn-primary btn-md mt-3 float-start"
        >
          Generate Estimation
        </button>
      </form>

      <div style={{ display: "none" }}>
        <div ref={invoiceRef}>
          <h3>Invoice</h3>
          <p>
            <strong>Company Name:</strong> {formData.company_name}
          </p>
          <p>
            <strong>Date:</strong>{" "}
            {formData.date || new Date().toLocaleDateString()}
          </p>
          <h4>Items</h4>
          <ul>
            {rows.map((row, index) => (
              <li key={row.id}>
                {row.item} - {row.price}
              </li>
            ))}
          </ul>
          <h4>Total Amount: {formData.total}</h4>
        </div>
      </div>

      <div className="container " style={{ marginTop: "10vh" }}>
        <table className="table striped ">
          <thead className="table-secondary">
            <tr>
              <th>Estimation No</th>
              <th>Company Name</th>
              <th>contact No</th>
              <th>Date</th>
              <th>Total</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {estimationData.map((each, index) => (
              <tr key={each.id}>
                <td>{each.est_no}</td>
                <td>{each.company_name}</td>
                <td>{each.contact_number}</td>
                <td>{each.date}</td>
                <td>{each.total}</td>
                <td>
                  <button
                    type="button"
                    className="btn btn-sm btn-success me-2"
                    onClick={() => handleView(each.est_no)}
                    data-bs-toggle="modal"
                    data-bs-target="#exampleModal"
                  >
                    View
                  </button>
                  <button
                    type="button"
                    className="btn btn-sm btn-warning me-2"
                    onClick={() => handleEdit(each.est_no)}
                  >
                    Edit
                  </button>
                  <button
                    type="button"
                    className="btn btn-sm btn-danger"
                    onClick={() => handleDelete(each.est_no)}
                  >
                    Delete
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <div
        className="modal fade"
        id="exampleModal"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-xl">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title fs-5" id="exampleModalLabel">
                Estimation View
              </h1>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <table className="table striped">
                <thead className="table-secondary">
                  <tr>
                    <th>Estimation No</th>
                    <th>Company Name</th>
                    <th>Contact No</th>
                    <th>Date</th>
                    <th>Item</th>
                    <th>Quantity</th>
                    <th>Rate</th>
                    <th>Amount</th>
                    <th>Sub Total</th>
                    <th>Discount</th>
                    <th>Tax</th>
                    <th>Total</th>
                  </tr>
                </thead>
                <tbody>
                  {estViewData.map((each, index) => (
                    <tr key={each.id}>
                      <td>{each.est_no}</td>
                      <td>{each.company_name}</td>
                      <td>{each.contact_number}</td>
                      <td>{each.date}</td>
                      <td>{each.item}</td>
                      <td>{each.quantity}</td>
                      <td>{each.rate}</td>
                      <td>{each.amount}</td>
                      <td>{each.sub_total}</td>
                      <td>{each.discount}</td>
                      <td>{each.taxamount}</td>
                      <td>{each.total}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Estimation;

import React, { useState } from "react";
import "./Bill.css";
import Estimation from "./Estimation";
import Invoice from "./Invoice";

const Bill = () => {
  const [selected, setSelected] = useState("estimation");

  const handleClick = (type) => {
    setSelected(type);
  };

  return (
    <React.Fragment>
      <div className="text-center mt-3">
        <div className="toggle-inv">
          <button
            className={`invbutton ${
              selected === "estimation" ? "active" : ""
            } `}
            onClick={() => handleClick("estimation")}
          >
            Estimation
          </button>
          <button
            className={`invbutton ${selected === "invoice" ? "active" : ""} `}
            onClick={() => handleClick("invoice")}
          >
            Invoice
          </button>
        </div>
      </div>

      <div className="form-content mt-4">
        {selected === "estimation" ? <Estimation /> : <Invoice />}
      </div>
    </React.Fragment>
  );
};

export default Bill;


import './App.css';
import Bill from './Bill';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';

function App() {
  return (
   
    <div className="App">
       <ToastContainer position="top-center"/>
     <Bill />
    </div>
  );
}

export default App;
